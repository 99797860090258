// Italic fonts are intentionally not loaded to improve performance.
import { cssBundleHref } from "@remix-run/css-bundle";
import {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  json,
  redirect } from
"@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { useEffect } from "react";
import "../node_modules/@fontsource/pt-sans/400.css?__remix_sideEffect__";
import "../node_modules/@fontsource/pt-sans/700.css?__remix_sideEffect__";
import "./styles/global.css?__remix_sideEffect__";
import { getLanguage } from "./utils";

export const action = async () => {
  return redirect("/de/9002");
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const sentryDSN = process.env["SENTRY_DSN"];
  if (!sentryDSN) throw Error("Missing SENTRY_DSN env variable");

  const url = new URL(request.url);
  return json({
    language: getLanguage(url),
    sentryDSN
  });
};

export const meta: MetaFunction = () => [{ title: "Meta search landing page" }];

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];


function App() {
  const { language, sentryDSN } = useLoaderData<typeof loader>();

  useEffect(() => {
    window.UC_UI?.updateLanguage(language);
  }, [language]);

  return (
    <html lang={language}>
      <head>
        {commonHeadContent}
        <Meta />

        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        {/*
           Set a client environment variable for the client
           (see https://remix.run/docs/en/v1/guides/envvars#browser-environment-variables).
          */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = {SENTRY_DSN: "${sentryDSN}"}`
          }} />

        <Scripts />

        <LiveReload />
      </body>
    </html>);

}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <html lang="en">
        <head>
          <title>Unexpected caught error</title>
          {commonHeadContent}
          <Links />
        </head>
        <body>
          <h1>
            {error.status === 404 ?
            <>Page not found</> :

            <> Unexpected caught error</>
            }
          </h1>
        </body>
      </html>);

  }

  return (
    <html lang="en">
      <head>
        <title>Unexpected error</title>
        {commonHeadContent}
        <Links />
      </head>
      <body>
        <h1>Unexpected error</h1>
        <p>An unexpected error occurred. Please try again later.</p>
      </body>
    </html>);

}

const commonHeadContent =
<>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="icon" sizes="16x16" href="/favicon.ico?v=2" />
    <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
    <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    <link rel="manifest" href="/site.webmanifest" />
  </>;